module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.14.0_gatsby@5.13.3_@types+webpack@4.41.38_babel-eslint@10.1_euxpivarsamjsjk4cpmesgmvxy/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TN8BZ3R","includeInDevelopment":true,"gtmAuth":"yhavHfFWirxKJ101d63aFg","gtmPreview":"env-1","routeChangeEventName":"spa-route-change","enableWebVitalsTracking":false,"defaultDataLayer":null,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-react-i18next@3.0.1_gatsby@5.13.3_@types+webpack@4.41.38_babel-eslint@10.1.0_es_a7u4qil2rgk2brnshqq5yurh4e/node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","redirect":false,"languages":["en","de"],"defaultLanguage":"en","trailingSlash":"never","i18nextOptions":{"interpolation":{"escapeValue":false},"saveMissing":true,"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang/(.*)","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/:lang","getLanguageFromPath":true,"excludeLanguages":["en","de"]},{"matchPath":"/","getLanguageFromPath":true,"excludeLanguages":["en","de"]}]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.13.3_@types+webpack@4.41.38_babel-eslint@10.1.0_eslint@7.32.0__encoding@0.1.13_esbui_ane4xngg4x4xqr75dx3ayfwbk4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
